%section-min-height {
  height: calc(100vh - 64px);
  min-height: 600px;
  // max-height: 800px;
}

.circulaire-economie {
  // @extend %section-min-height;

  &__inner {
    height: 100%;
    display: flex;
    box-shadow: 0 0 8px rgba(#000, .2);
  }

  &__main,
  &__visual {
    margin: 0;
    width: 50%;
    position: relative;
  }

  &__visual {

    @include above($tablet) {
      height: calc(100vh - 64px);
      min-height: 600px;
    }

  }

  &__main {
    position: relative;

    &::before {
      content: '';
      display: block;
      width: calc(100% - 20px);
      height: 100px;
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(to top, rgba(255,255,255, 0),rgba(255,255,255, 1));
    }

    &::after {
      content: '';
      display: block;
      width: calc(100% - 20px);
      height: 100px;
      z-index: 1;
      position: absolute;
      bottom: 0;
      left: 0;
      background: linear-gradient(to bottom, rgba(255,255,255, 0),rgba(255,255,255, 1));
    }
  }

  &__main__inner {
    max-width: 500px;
    padding: rem(20);
    margin: auto;
    position: relative;
    z-index: 0;
    height: 100%;

    article {
      padding: 40px 0 60px 0;
    }
  }

  .slider {
    width: 100%;

    &__slide {
      @extend %section-min-height;
      width: 100%;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
}

@include below($desktop-hd) {
  .circulaire-economie__main__inner article {
    padding-top: 20px;
  }
}

@include below($desktop) {
  .circulaire-economie__main__inner article {
    padding-top: 0;
    padding-bottom: 20px;
  }

  .circulaire-economie__main {

    &::before {
      height: 50px;
    }

    &::after {
      height: 50px;
    }
  }
}


@include below($tablet) {
  .circulaire-economie {
    height: auto;
    min-height: 0;
    max-height: 100%;

    &__inner {
      min-height: auto;
      flex-direction: column;
    }

    &__main,
    &__visual { width: 100%; }

    &__visual {
      order: 2;

    }

    &__main {
      order: 1;

      &::before,
      &::after {
        display: none;
      }
    }

    &__main__inner {
      max-width: 100%;
      article { padding: 0 }
    }

    .slider__slide {
      height: 370px;
      min-height: 0;
      max-height: 100%;
    }
  }
}
