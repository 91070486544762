.progressbar {
  position: relative;
  height: 8px;
  width: 210px;
  height: 8px;
  border-radius: 4px;
  background-color: #E0E0E0;
  padding: 2px 3px;


  &__bar {
    height: 4px;
    border-radius: 4px;
    background-color: $mint;
  }
}
