.logos-grid {
  margin-top: rem(80);
  text-align: center;
  vertical-align: middle;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  img {

  }

  img.new-badge {
    width: rem(80);
    height: auto;
    margin: 0 20px 0 0;
  }

  img.network-logo {
    margin: 0 20px;
    max-height: 40px;
    width: auto;

    &[alt="Match"] {
      max-height: 30px;
    }

    &[alt="Vandenborre"] {
      max-height: 30px;
    }
  }
}


@include below($tablet) {
  .logos-grid {
    margin-top: rem(40);
  }
}
