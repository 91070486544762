@media screen and (max-width: 1440px) {
  .foo {
    bar: 'below'; } }

@media screen and (max-width: 1439px) {
  .foo {
    bar: 'below -1'; } }

@media screen and (max-width: 1280px) {
  .foo {
    bar: 'below'; } }

@media screen and (max-width: 1279px) {
  .foo {
    bar: 'below -1'; } }

@media screen and (max-width: 1100px) {
  .foo {
    bar: 'below'; } }

@media screen and (max-width: 1099px) {
  .foo {
    bar: 'below -1'; } }

@media screen and (max-width: 990px) {
  .foo {
    bar: 'below'; } }

@media screen and (max-width: 989px) {
  .foo {
    bar: 'below -1'; } }

@media screen and (max-width: 768px) {
  .foo {
    bar: 'below'; } }

@media screen and (max-width: 767px) {
  .foo {
    bar: 'below -1'; } }

@media screen and (max-width: 600px) {
  .foo {
    bar: 'below'; } }

@media screen and (max-width: 599px) {
  .foo {
    bar: 'below -1'; } }

@media screen and (max-width: 480px) {
  .foo {
    bar: 'below'; } }

@media screen and (max-width: 479px) {
  .foo {
    bar: 'below -1'; } }

@media screen and (min-width: 479px) {
  .foo {
    bar: 'above -1'; } }

@media screen and (min-width: 480px) {
  .foo {
    bar: 'above'; } }

@media screen and (min-width: 599px) {
  .foo {
    bar: 'above -1'; } }

@media screen and (min-width: 600px) {
  .foo {
    bar: 'above'; } }

@media screen and (min-width: 767px) {
  .foo {
    bar: 'above -1'; } }

@media screen and (min-width: 768px) {
  .foo {
    bar: 'above'; } }

@media screen and (min-width: 989px) {
  .foo {
    bar: 'above -1'; } }

@media screen and (min-width: 990px) {
  .foo {
    bar: 'above'; } }

@media screen and (min-width: 1099px) {
  .foo {
    bar: 'above -1'; } }

@media screen and (min-width: 1100px) {
  .foo {
    bar: 'above'; } }

@media screen and (min-width: 1279px) {
  .foo {
    bar: 'above -1'; } }

@media screen and (min-width: 1280px) {
  .foo {
    bar: 'above'; } }

@media screen and (min-width: 1439px) {
  .foo {
    bar: 'above -1'; } }

@media screen and (min-width: 1440px) {
  .foo {
    bar: 'above'; } }

@font-face {
  font-family: "Icomoon";
  font-weight: 400;
  font-style: normal;
  src: url("../fonts/icomoon/icomoon.woff2") format("woff2"), url("../fonts/icomoon/icomoon.woff") format("woff"); }

@font-face {
  font-family: "Lato";
  font-weight: 100;
  font-style: normal;
  src: url("../fonts/lato/lato-hairline-webfont.woff2") format("woff2"), url("../fonts/lato/lato-hairline-webfont.woff") format("woff"); }

@font-face {
  font-family: "Lato";
  font-weight: 100;
  font-style: italic;
  src: url("../fonts/lato/lato-hairlineitalic-webfont.woff2") format("woff2"), url("../fonts/lato/lato-hairlineitalic-webfont.woff") format("woff"); }

@font-face {
  font-family: "Lato";
  font-weight: 300;
  font-style: normal;
  src: url("../fonts/lato/lato-light-webfont.woff2") format("woff2"), url("../fonts/lato/lato-light-webfont.woff") format("woff"); }

@font-face {
  font-family: "Lato";
  font-weight: 300;
  font-style: italic;
  src: url("../fonts/lato/lato-lightitalic-webfont.woff2") format("woff2"), url("../fonts/lato/lato-lightitalic-webfont.woff") format("woff"); }

@font-face {
  font-family: "Lato";
  font-weight: 400;
  font-style: normal;
  src: url("../fonts/lato/lato-regular-webfont.woff2") format("woff2"), url("../fonts/lato/lato-regular-webfont.woff") format("woff"); }

@font-face {
  font-family: "Lato";
  font-weight: 400;
  font-style: italic;
  src: url("../fonts/lato/lato-italic-webfont.woff2") format("woff2"), url("../fonts/lato/lato-italic-webfont.woff") format("woff"); }

@font-face {
  font-family: "Lato";
  font-weight: 500;
  font-style: normal;
  src: url("../fonts/lato/lato-medium-webfont.woff2") format("woff2"), url("../fonts/lato/lato-medium-webfont.woff") format("woff"); }

@font-face {
  font-family: "Lato";
  font-weight: 500;
  font-style: italic;
  src: url("../fonts/lato/lato-mediumitalic-webfont.woff2") format("woff2"), url("../fonts/lato/lato-mediumitalic-webfont.woff") format("woff"); }

@font-face {
  font-family: "Lato";
  font-weight: 600;
  font-style: normal;
  src: url("../fonts/lato/lato-semibold-webfont.woff2") format("woff2"), url("../fonts/lato/lato-semibold-webfont.woff") format("woff"); }

@font-face {
  font-family: "Lato";
  font-weight: 600;
  font-style: italic;
  src: url("../fonts/lato/lato-semibolditalic-webfont.woff2") format("woff2"), url("../fonts/lato/lato-semibolditalic-webfont.woff") format("woff"); }

@font-face {
  font-family: "Lato";
  font-weight: 700;
  font-style: normal;
  src: url("../fonts/lato/lato-bold-webfont.woff2") format("woff2"), url("../fonts/lato/lato-bold-webfont.woff") format("woff"); }

@font-face {
  font-family: "Lato";
  font-weight: 700;
  font-style: italic;
  src: url("../fonts/lato/lato-bolditalic-webfont.woff2") format("woff2"), url("../fonts/lato/lato-bolditalic-webfont.woff") format("woff"); }

@font-face {
  font-family: "Lato";
  font-weight: 800;
  font-style: normal;
  src: url("../fonts/lato/lato-heavy-webfont.woff2") format("woff2"), url("../fonts/lato/lato-heavy-webfont.woff") format("woff"); }

@font-face {
  font-family: "Lato";
  font-weight: 800;
  font-style: italic;
  src: url("../fonts/lato/lato-heavyitalic-webfont.woff2") format("woff2"), url("../fonts/lato/lato-heavyitalic-webfont.woff") format("woff"); }

@font-face {
  font-family: "Lato";
  font-weight: 900;
  font-style: normal;
  src: url("../fonts/lato/lato-black-webfont.woff2") format("woff2"), url("../fonts/lato/lato-black-webfont.woff") format("woff"); }

@font-face {
  font-family: "Lato";
  font-weight: 900;
  font-style: italic;
  src: url("../fonts/lato/lato-blackitalic-webfont.woff2") format("woff2"), url("../fonts/lato/lato-blackitalic-webfont.woff") format("woff"); }

html {
  box-sizing: border-box;
  line-height: 1.15; }

*,
*::before,
*::after {
  box-sizing: inherit; }

:root {
  -moz-tab-size: 4;
  tab-size: 4; }

body {
  margin: 0;
  font-family: "Lato", sans-serif;
  color: #444;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

h1 {
  font-size: 2em;
  margin: 0.67em 0; }

hr {
  height: 0; }

abbr[title] {
  text-decoration: underline dotted; }

b,
strong {
  font-weight: bolder; }

code,
kbd,
samp,
pre {
  font-family: SFMono-Regular, Consolas, 'Liberation Mono', Menlo, Courier, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  color: inherit; }

button,
select {
  text-transform: none; }

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0; }

button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: 1px dotted ButtonText; }

fieldset {
  padding: 0.35em 0.75em 0.625em; }

legend {
  padding: 0; }

progress {
  vertical-align: baseline; }

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto; }

[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px; }

[type='search']::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

summary {
  display: list-item; }

a, a:link, a:hover, a:active, a:visited {
  color: #84c92c; }

nav ol,
nav ul {
  list-style: none;
  padding: 0;
  margin: 0; }

nav a {
  text-decoration: none;
  color: inherit; }

button {
  padding: 0;
  appearance: none;
  border: none;
  background: transparent;
  cursor: pointer; }

.wrapper {
  width: 100%;
  position: relative; }

.screenReader {
  position: absolute;
  top: 0;
  left: -99999rem;
  display: block;
  height: 0;
  text-align: left;
  text-indent: -99999rem; }

.clr-blue {
  color: #0078ae; }

.clr-blue-light {
  color: #5fceea; }

.clr-mint {
  color: #15dcbe; }

.clr-green {
  color: #84c92c; }

h1, h2 {
  font-size: 2rem;
  line-height: 1.25;
  font-weight: 700;
  color: #15dcbe; }
  .board h1,
  .balance h1, .board h2,
  .balance h2 {
    margin: 1.5em 0; }
  @media screen and (max-width: 990px) {
    h1, h2 {
      font-size: 1.625rem;
      line-height: 1.38462; } }
  @media screen and (max-width: 480px) {
    h1, h2 {
      font-size: 1.5rem;
      line-height: 1.33333; } }

h3, h4, h5 {
  color: #587d83; }

h3 {
  font-size: 1.25rem;
  line-height: 1.6;
  font-weight: 400;
  color: #587d83; }

h1.site-title {
  font-size: 1.5rem;
  margin: 0;
  font-weight: 700;
  color: #587d83; }

p {
  font-size: 1rem;
  line-height: 1.5; }

.constrict {
  max-width: 1100px;
  padding: 0 30px;
  margin: auto; }

blockquote {
  color: #587d83;
  margin: 2em 0;
  padding-left: 1.875rem;
  border-left: 8px solid #15dcbe; }
  blockquote p {
    margin: 0; }
  blockquote footer {
    color: #15dcbe;
    font-weight: 700;
    margin-top: 1em; }
  .future blockquote {
    border-color: currentColor; }
    .future blockquote, .future blockquote footer {
      color: inherit; }

.btn--primary {
  font-size: 1.25rem;
  font-weight: 700;
  background-color: #15dcbe;
  background-image: linear-gradient(to right, #5fceea 0%, #15dcbe 51%, #5fceea 100%);
  background-size: 200% 100%;
  text-decoration: none;
  color: #fff;
  display: inline-block;
  padding: 1em 2em;
  border-radius: 4px;
  transition-duration: .5s;
  position: relative; }
  .btn--primary::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 35%, rgba(255, 255, 255, 0.4) 51%, rgba(255, 255, 255, 0) 65%, rgba(255, 255, 255, 0) 100%);
    background-size: 200% 100%;
    transition: all .3s ease-in-out;
    opacity: 0; }
  .btn--primary:hover, .btn--primary:hover::before {
    background-position: right center;
    opacity: 1; }

a.btn--primary {
  color: #fff; }

.btn--small {
  font-size: 1rem;
  padding: 7px 14px; }

.btn--outline {
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  outline: 0;
  font-weight: 400; }

.cards-grid {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between; }
  .cards-grid--2 .card {
    width: calc(50% - 20px); }
  .cards-grid--3 .card {
    width: calc(33.333% - 20px); }
  .network .cards-grid {
    align-items: stretch;
    max-width: 1380px; }

.card {
  background: #fff;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 1.875rem;
  position: relative; }
  .card p {
    margin: 1em 0 0; }
  .card__title {
    margin: 0;
    font-weight: 400;
    font-size: 1.25rem; }
  .card__title-lrg {
    margin: 0;
    font-size: 1.5rem;
    color: #0078ae; }
  .card__meta {
    display: flex;
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #E0E0E0; }
    .card__meta__detail {
      flex-grow: 1; }
      .card__meta__detail:nth-child(odd) {
        color: #5fceea; }
      .card__meta__detail:nth-child(even) {
        color: #15dcbe; }
    .card__meta__detail + .card__meta__detal {
      width: 50%; }
    .card__meta__result, .card__meta__title {
      display: block; }
    .card__meta__title {
      font-weight: 700; }
    .card__meta__result {
      font-weight: 300;
      font-size: 2.5rem;
      line-height: 1;
      margin-top: .3em; }

.card--lrg {
  padding: 1.875rem;
  margin-top: 1.875rem; }
  .card--lrg .card__visual {
    position: absolute;
    top: 0.625rem;
    right: 1.875rem;
    z-index: 0;
    opacity: .3; }
  .card--lrg .card__content {
    position: relative;
    z-index: 1; }
  .card--lrg .card__count {
    font-weight: 400;
    font-size: 4.5rem;
    position: absolute;
    color: #15dcbe;
    top: -3.125rem;
    left: 1.375rem; }
  .card--lrg.card--1 .icn {
    width: 87px;
    height: 112px; }
  .card--lrg.card--2 .icn {
    width: 65px;
    height: 110px; }
  .card--lrg.card--2 .card__count {
    left: 1.875rem; }
  .card--lrg:nth-of-type(1) .card__visual {
    transform: rotate(90deg); }

.card::before, .card::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  transition: all .2s ease-in-out; }

.card::before {
  margin-left: -37px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 37px 37px 37px;
  border-color: transparent transparent #fff transparent; }

.card::after {
  width: 20px;
  height: 20px;
  opacity: 0;
  margin-left: -10px;
  border-style: solid;
  border-color: #15dcbe;
  border-width: 2px 2px 0 0;
  transform: rotate(-45deg); }

.card.is-active::before, .card.is-active::after {
  opacity: 1; }

.card.is-active::before {
  top: -37px; }

.card.is-active::after {
  top: -10px; }

.network__grid .card__inner {
  overflow: hidden; }

@media screen and (max-width: 600px) {
  .results__how .card--1,
  .results__how .card--2 {
    margin-left: -1.875rem;
    margin-right: -1.875rem;
    border-radius: 0; }
  .cards-grid {
    position: relative;
    display: block; }
    .cards-grid--2 .card {
      width: auto; }
    .cards-grid--3 .card {
      width: auto; }
    .network .cards-grid {
      align-items: stretch;
      max-width: 1380px; } }

.chart-wrapper-mobile {
  display: none; }

.departments {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around; }

.department {
  text-align: center;
  width: 220px;
  margin-bottom: 3.75rem; }
  .department__header {
    margin-bottom: 10px; }
  .department__header__inner {
    display: flex;
    justify-content: center;
    align-items: center; }
  .department__title {
    font-size: 1.25rem;
    font-weight: 400;
    color: #0078ae;
    margin: 0 0 0 10px; }
  .department__icon {
    width: 30px;
    height: 30px; }
    .department__icon .icn {
      width: 100%;
      height: 100%; }

.members {
  display: flex;
  flex-wrap: wrap; }

.member {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%; }
  .member__avatar .member__name, .member__function, .member__department {
    display: block; }
  .member__avatar {
    width: 100px;
    height: 100px;
    background-size: cover;
    background-position: center; }
  .member__name {
    font-size: 0.875rem;
    font-weight: 700;
    margin: 1em 0 0;
    color: #5fceea; }
  .member__function, .member__department {
    font-size: 0.75rem;
    color: rgba(0, 0, 0, 0.5);
    margin: .2em 0 0 0; }

.event-content-area {
  position: relative;
  z-index: 1; }
  @media screen and (max-width: 990px) {
    .event-content-area {
      display: none; } }
  .event-content-area__ui {
    position: relative;
    padding: 0 30px;
    max-width: 700px;
    margin: auto; }
    .event-content-area__ui .timeline-close {
      margin-right: 30px; }
  .event-content-area__placeholder {
    opacity: 1;
    position: relative;
    transform: translateY(0);
    transition-property: opacity, transform;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out; }
    .event-content-area__placeholder.is-hidden {
      opacity: 0;
      transform: translateY(100px); }
  .event-content-area__close {
    outline: 0;
    position: absolute;
    width: 20px;
    height: 20px;
    right: 0;
    z-index: 1;
    opacity: 1;
    transform: translateY(0);
    transition-property: opacity, transform;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out; }
    .event-content-area__close .icn {
      width: 100%;
      height: 100%; }
    .event-content-area__close.is-hidden {
      opacity: 0;
      transform: translateY(100px); }
    @media screen and (max-width: 990px) {
      .event-content-area__close {
        display: none; } }

@media screen and (min-width: 990px) {
  .event {
    height: 100%;
    border-top: 1px solid #fff; } }

.event__teaser {
  height: 20.5rem;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover; }
  @media screen and (min-width: 990px) {
    .event__teaser {
      height: 100%; } }
  @media screen and (max-width: 990px) {
    .event__teaser {
      transition-property: height;
      transition-duration: 0.3s;
      transition-timing-function: ease-in-out; } }
  .event__teaser::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: red;
    z-index: 0;
    opacity: .9;
    transition-property: height;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out; }

.event__teaser__inner {
  position: relative;
  z-index: 1;
  height: 100%; }

.event__teaser__title {
  position: absolute;
  margin: 0;
  color: #fff;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.6;
  margin: 0;
  top: 0;
  padding: 50px 30px 30px;
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out; }

.event__indicator {
  height: 100%;
  position: absolute;
  transition-property: height;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  bottom: 0;
  left: 10px;
  right: 0; }
  .event__indicator::before {
    content: '';
    display: block;
    width: 1px;
    background-color: #fff;
    position: absolute;
    top: 0;
    bottom: 0; }

.event__date {
  position: absolute;
  color: #fff;
  left: 20px;
  top: 20px;
  transition: top .3s ease-in-out; }
  .event__date::before {
    content: '';
    display: block;
    width: 14px;
    height: 1px;
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: -20px; }
    @media screen and (min-width: 990px) {
      .event__date::before {
        display: none; } }

.event__next {
  margin-top: 2.5rem;
  border-top: 1px solid #15dcbe;
  padding: 1.25rem 0 0;
  font-size: 1rem;
  line-height: 1.5;
  display: flex;
  align-items: center; }
  @media screen and (min-width: 990px) {
    .event__next {
      cursor: pointer; } }
  @media screen and (max-width: 990px) {
    .event__next {
      display: none; } }
  .event__next__inner {
    flex-grow: 1;
    padding-right: 60px; }
  .event__next .icn {
    width: auto;
    height: auto; }
  .event__next__date {
    color: #15dcbe;
    font-size: inherit;
    font-weight: 700; }
  .event__next__title {
    margin: 0;
    font-size: inherit;
    font-weight: 400; }
    .event__next__title br {
      display: none; }

.event.is-active .event__teaser::before {
  height: 0; }

.event.is-active .event__date {
  top: calc(100% - 40px); }

.event.is-active .event__teaser__title {
  top: 100%;
  opacity: 0; }

.event__content__close {
  position: absolute;
  width: 20px;
  height: 20px;
  right: 30px;
  z-index: 1; }
  .event__content__close .icn {
    width: 100%;
    height: 100%; }
  @media screen and (min-width: 990px) {
    .event__content__close {
      overflow-y: scroll; } }
  @media screen and (max-width: 990px) {
    .event__content__close {
      display: none; } }

.event__content__inner {
  padding: 0 30px;
  padding-bottom: 30px;
  max-width: 700px;
  margin: auto; }
  @media screen and (max-width: 990px) {
    .event__content__inner {
      padding: 30px; } }
  .event__content__inner h2 {
    margin-top: 0; }
    @media screen and (max-width: 1650px) {
      .event__content__inner h2 {
        font-size: 1.625rem;
        line-height: 1.15385; } }
    @media screen and (max-width: 1100px) {
      .event__content__inner h2 {
        font-size: 1.5rem;
        line-height: 1.25; } }
  .event__content__inner p {
    margin: .5em 0 0 0; }
    @media screen and (max-width: 1650px) {
      .event__content__inner p {
        font-size: 0.9375rem; } }

.event__content__visual {
  height: 0;
  padding-bottom: 35%;
  margin: 1.25rem 0;
  background-position: center 15%;
  background-size: cover; }
  @media screen and (max-width: 1440px) {
    .event__content__visual {
      padding-bottom: 30%;
      background-position: center 10%; } }
  @media screen and (max-width: 990px) {
    .event__content__visual {
      display: none; } }

.event__content__date {
  color: #15dcbe;
  font-size: 1rem;
  font-weight: 700; }
  @media screen and (max-width: 1650px) {
    .event__content__date {
      font-size: 0.875rem; } }
  @media screen and (max-width: 990px) {
    .event__content__date {
      display: none; } }

.event[data-color="blue"] .event__teaser::before {
  background-color: #0078ae; }

.event[data-color="blue-light"] .event__teaser::before {
  background-color: #5fceea; }

.event[data-color="smoke"] .event__teaser::before {
  background-color: #587d83; }

.event[data-color="mint"] .event__teaser::before {
  background-color: #15dcbe; }

@media screen and (min-width: 990px) {
  .event__teaser__title {
    padding: 30px;
    position: absolute; }
  .event__indicator {
    height: 80px; }
  .event__content__inner {
    display: flex;
    flex-direction: column; }
    .event__content__inner__copy {
      flex-grow: 1; }
  .timeline .event__teaser__title {
    font-size: 1.5rem;
    line-height: 1.66667; }
  .timeline .event[data-row="1"] .event__inner::before {
    bottom: 0;
    top: auto; }
  .timeline .event[data-row="1"] .event__teaser__title {
    top: 0;
    bottom: auto; }
  .timeline .event[data-row="1"] .event__indicator {
    bottom: 0;
    top: auto;
    left: 1.875rem; }
  .timeline .event[data-row="1"] .event__date {
    top: 0;
    bottom: auto; }
    .timeline .event[data-row="1"] .event__date::before {
      display: none; }
  .timeline .event[data-row="2"] .event__inner::before {
    top: 0;
    bottom: auto; }
  .timeline .event[data-row="2"] .event__teaser__title {
    bottom: 0;
    top: auto; }
  .timeline .event[data-row="2"] .event__indicator {
    top: 0;
    bottom: auto;
    left: 5rem; }
  .timeline .event[data-row="2"] .event__date {
    bottom: 0;
    top: auto; }
  .event:hover {
    cursor: pointer; }
    .event:hover .event__inner::before {
      height: 0; }
    .event:hover .event__indicator {
      height: 6.25rem; }
  .timeline.is-open .timeline__block.span-2 {
    width: 25%; }
  .timeline.is-open .event__teaser__title {
    font-size: 1.125rem;
    line-height: 1.33333; }
  .timeline.is-open .event[data-row="1"] .event__teaser__title,
  .timeline.is-open .event[data-row="2"] .event__teaser__title {
    bottom: auto;
    top: 0; }
  .timeline.is-open .event[data-row="1"] .event__indicator,
  .timeline.is-open .event[data-row="2"] .event__indicator {
    bottom: 0;
    top: auto;
    height: 2.5rem;
    left: 1.875rem; }
  .timeline.is-open .event[data-row="1"] .event__date,
  .timeline.is-open .event[data-row="2"] .event__date {
    bottom: auto;
    top: 0;
    font-size: 0.875rem; }
  .timeline.is-open .event[data-row="1"]:hover .event__indicator,
  .timeline.is-open .event[data-row="2"]:hover .event__indicator {
    height: 3.125rem; } }

@media screen and (min-width: 990px) and (max-height: 700px), screen and (min-width: 990px) and (max-width: 1100px) {
  .event__content__inner p {
    font-size: 0.875rem; }
  .timeline .event__teaser__title {
    font-size: 1.25rem;
    line-height: 1.2; }
  .timeline .event__date {
    font-size: 0.75rem; }
  .timeline.is-open .event__teaser__title {
    font-size: 1rem;
    line-height: 1.2; }
  .timeline .event[data-row="1"] .event__indicator,
  .timeline .event[data-row="2"] .event__indicator, .timeline.is-open .event[data-row="1"] .event__indicator,
  .timeline.is-open .event[data-row="2"] .event__indicator {
    height: 30px; }
  .timeline .event[data-row="1"]:hover .event__indicator,
  .timeline .event[data-row="2"]:hover .event__indicator, .timeline.is-open .event[data-row="1"]:hover .event__indicator,
  .timeline.is-open .event[data-row="2"]:hover .event__indicator {
    height: 40px; } }

.footer {
  height: 100px;
  background-color: #fafafa; }
  .footer__inner {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 1.25rem; }

a.footer-link {
  color: #5fceea;
  font-size: 1.5rem;
  font-weight: 700;
  text-decoration: none; }
  @media screen and (max-width: 990px) {
    a.footer-link {
      font-size: 1.25rem; } }
  @media screen and (max-width: 480px) {
    a.footer-link {
      font-size: 1.125rem; } }
  a.footer-link i {
    vertical-align: middle;
    display: inline-block;
    line-height: 1.6;
    width: auto;
    height: auto;
    margin-left: .3em; }
  a.footer-link:hover span {
    text-decoration: underline; }

.goals {
  background-color: #15dcbe;
  background-image: linear-gradient(to top, #15dcbe, #5fceea);
  color: #fff;
  height: calc(100vh - 64px);
  min-height: 512px;
  position: relative; }
  .goals__inner {
    position: relative;
    height: 100%; }
  .goals__pager {
    position: absolute;
    width: 2rem;
    top: 50%;
    left: -70px; }
  .goals__content, .goals__visuals {
    height: 100%;
    position: relative; }
  .goals__content {
    position: relative;
    z-index: 1; }
  .goals__visuals {
    position: absolute;
    top: 0;
    right: 0;
    width: 50%; }
  .goals__content__inner {
    width: 50%;
    height: 100%;
    position: relative; }
  .goals__content__title {
    font-size: 2rem;
    line-height: 1.25;
    color: #fff;
    margin: 0;
    position: absolute;
    top: 120px; }

.goal-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }
  .goal-wrapper__inner {
    overflow-y: scroll;
    position: relative; }

.goal {
  width: 100%;
  left: 0;
  margin-bottom: 0; }
  .goal__title {
    font-size: 2rem;
    line-height: 1.25;
    margin: 0 0 40px;
    font-weight: 400;
    color: #fff; }
    .goal__title span {
      font-weight: 700; }
  .goal p {
    max-width: 350px;
    width: 100%;
    margin-bottom: 0; }
  .goal__visual {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    transition: opacity .6s ease-in-out; }
    .goal__visual.is-active {
      opacity: 1; }

@media screen and (max-width: 1280px) {
  .goals__pager {
    left: 0;
    transform: translateY(-120px); } }

@media screen and (max-width: 990px) {
  .goals {
    height: auto;
    min-height: 0; }
  .goals__inner {
    position: relative; }
  .goals__pager {
    margin: 0;
    top: auto;
    left: auto;
    padding: 30px 0 20px;
    position: relative;
    transform: translateX(0); }
  .goal-wrapper {
    top: auto;
    margin-bottom: 30px;
    position: relative; }
  .goals__content {
    position: relative;
    transform: none;
    top: auto;
    width: auto; }
  .goals__content__title {
    margin-top: 0;
    top: auto;
    font-size: 1.5rem;
    line-height: 1.66667;
    position: relative; }
  .goals__visuals {
    display: none; }
  .goals__content__inner {
    width: 100%; }
  .goal {
    width: auto;
    right: 30px; }
    .goal__title {
      font-size: 1.5rem;
      line-height: 1.66667;
      margin-bottom: 24px; } }

.header {
  height: 64px;
  box-shadow: 0 1px 1px rgba(222, 222, 222, 0.5);
  z-index: 9;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fff; }
  .header__inner {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 1.25rem; }
  .header__logo {
    width: 135px;
    height: 39px; }
  .header__title {
    flex-grow: 1;
    padding-left: 20px; }

.header-placeholder {
  height: 64px; }

@media screen and (max-width: 990px) {
  .header__inner {
    justify-content: space-between; }
  .header__language,
  .header__title {
    display: none; } }

.icn::before, .slick-arrow::before {
  font-family: 'Icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icn {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  display: inline-block;
  width: 40px;
  height: 40px; }
  .icn--angle-left::before {
    content: '\f104'; }
  .icn--angle-right::before {
    content: '\f105'; }
  .icn--angle-up::before {
    content: '\f106'; }
  .icn--angle-down::before {
    content: '\f107'; }
  .icn--svg::before {
    display: none; }
  .icn--close {
    background-image: url("../img/icn-close.svg"); }
  .icn--washing-machine {
    background-image: url("../img/icn-washing-machine.svg"); }
  .icn--recycling {
    background-image: url("../img/icn-recycling.svg"); }
  .icn--target {
    background-image: url("../img/icn-target.svg"); }
  .icn--mixer {
    background-image: url("../img/icn-mixer.svg"); }
  .icn--lightbulb {
    background-image: url("../img/icn-lightbulb.svg"); }
  .icn--BW-REC {
    background-image: url("../img/departments/BW-REC.svg"); }
  .icn--AV {
    background-image: url("../img/departments/AV.svg"); }
  .icn--SDA {
    background-image: url("../img/departments/SDA.svg"); }
  .icn--ICT {
    background-image: url("../img/departments/ICT.svg"); }
  .icn--ETandG {
    background-image: url("../img/departments/ETandG.svg"); }
  .icn--LIGHTREC {
    background-image: url("../img/departments/LIGHTREC.svg"); }
  .icn--MELAREC {
    background-image: url("../img/departments/MELAREC.svg"); }
  .icn--triangle-up {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 12px 19px 12px;
    border-color: transparent transparent #15dcbe transparent; }
    .icn--triangle-up::before {
      display: none; }
  .icn--triangle-down {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 19px 12px 0 12px;
    border-color: #15dcbe transparent transparent transparent; }
    .icn--triangle-down::before {
      display: none; }

.logo {
  display: inline-block;
  width: 135px;
  height: 39px;
  background-color: transparent;
  background-image: url("../img/logo_full_color.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain; }

.logos-grid {
  margin-top: 5rem;
  text-align: center;
  vertical-align: middle;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center; }
  .logos-grid img.new-badge {
    width: 5rem;
    height: auto;
    margin: 0 20px 0 0; }
  .logos-grid img.network-logo {
    margin: 0 20px;
    max-height: 40px;
    width: auto; }
    .logos-grid img.network-logo[alt="Match"] {
      max-height: 30px; }
    .logos-grid img.network-logo[alt="Vandenborre"] {
      max-height: 30px; }

@media screen and (max-width: 990px) {
  .logos-grid {
    margin-top: 2.5rem; } }

.offscreen-menu-overlay {
  background-color: rgba(51, 51, 51, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  z-index: -99;
  will-change: z-index, opacity;
  transition-property: z-index, opacity;
  transition-duration: 0.3s;
  transition-delay: 0.3s, 0s;
  transition-timing-function: ease-in-out; }

.offscreen-menu {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  transform: translateX(100%);
  will-change: transform;
  transition-property: transform;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out; }
  .offscreen-menu__inner {
    width: 360px;
    max-width: 100%;
    background-color: #fff;
    box-shadow: 0 0 0 0 rgba(51, 51, 51, 0.5);
    padding: 40px 85px 50px 40px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    z-index: 1;
    will-change: box-whadow;
    transition-property: transform;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out; }

.show-menu .offscreen-menu-overlay {
  opacity: 1;
  z-index: 98;
  transition-delay: 0s, .1s; }

.show-menu .offscreen-menu {
  transform: translateX(0); }
  .show-menu .offscreen-menu__inner {
    box-shadow: -10px 0 8px 0 rgba(51, 51, 51, 0.5); }

@media screen and (max-width: 990px) {
  .offscreen-menu__inner {
    width: auto;
    padding: 30px; } }

.nav--lang .nav__item {
  display: inline-block;
  margin-right: 1em;
  font-size: 0.75rem; }
  .nav--lang .nav__item a {
    text-transform: uppercase;
    color: #444; }
  .nav--lang .nav__item.is-active a {
    color: #15dcbe; }

.nav--primary {
  margin-top: 20px; }
  .nav--primary .nav__item {
    font-size: 1rem;
    color: #444;
    line-height: 2.25; }
    .nav--primary .nav__item a {
      display: block;
      color: inherit; }
    .nav--primary .nav__item:hover, .nav--primary .nav__item:focus, .nav--primary .nav__item.is-active {
      color: #15dcbe; }
  .nav--primary .nav__item + .nav__item {
    border-top: 1px solid #eeeeee; }

.nav--secondary .nav__item {
  font-size: 1rem;
  color: #a3a3a3;
  line-height: 2.25; }
  .nav--secondary .nav__item a {
    display: block;
    color: inherit; }
  .nav--secondary .nav__item:hover, .nav--secondary .nav__item:focus, .nav--secondary .nav__item.is-active {
    color: #15dcbe; }

.nav--secondary .nav__item + .nav__item {
  border-top: 1px solid #eeeeee; }

.pager {
  position: absolute;
  top: 50%;
  margin-left: -30px;
  transform: translate(-100%, -100%);
  font-size: 0.75rem;
  width: 2rem;
  display: flex;
  flex-direction: column; }
  @media screen and (max-width: 1280px) {
    .pager {
      transform: translate(0, 0);
      top: 10vh;
      margin-left: 0; } }
  .pager .slick-dots, .pager__dots {
    list-style: none;
    padding: 0;
    margin: 0;
    order: 1; }
    .pager .slick-dots li, .pager__dots li {
      width: 100%;
      height: 4px;
      border-radius: 4px;
      overflow: hidden;
      background-color: #fff;
      opacity: .3;
      transition: opacity .3s ease-in-out;
      margin-bottom: 8px; }
      .pager .slick-dots li::before, .pager__dots li::before {
        content: '';
        display: block;
        width: 100%;
        height: 100%; }
      .pager .slick-dots li:hover, .pager__dots li:hover {
        opacity: .6; }
      .pager .slick-dots li.slick-active, .pager .slick-dots li.is-active, .pager__dots li.slick-active, .pager__dots li.is-active {
        opacity: 1; }
  .pager__counter {
    text-align: center;
    margin-top: 12px;
    order: 2; }

.percentage {
  color: #15dcbe;
  position: relative;
  display: inline-block;
  padding: 0 20px 0 0; }
  .percentage .visual {
    position: absolute;
    z-index: 0;
    right: 0;
    top: 0; }
  .percentage__value {
    position: relative;
    z-index: 1;
    font-weight: 300;
    font-size: 9rem; }
  .percentage__unit {
    font-weight: 400;
    font-size: .5em; }

.progressbar {
  position: relative;
  height: 8px;
  width: 210px;
  height: 8px;
  border-radius: 4px;
  background-color: #E0E0E0;
  padding: 2px 3px; }
  .progressbar__bar {
    height: 4px;
    border-radius: 4px;
    background-color: #15dcbe; }

.scrollable {
  height: 100%;
  margin-right: 20px;
  position: relative; }

.circulaire-economie .slider__slide {
  height: calc(100vh - 64px);
  min-height: 600px; }

.circulaire-economie__inner {
  height: 100%;
  display: flex;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2); }

.circulaire-economie__main, .circulaire-economie__visual {
  margin: 0;
  width: 50%;
  position: relative; }

@media screen and (min-width: 990px) {
  .circulaire-economie__visual {
    height: calc(100vh - 64px);
    min-height: 600px; } }

.circulaire-economie__main {
  position: relative; }
  .circulaire-economie__main::before {
    content: '';
    display: block;
    width: calc(100% - 20px);
    height: 100px;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(to top, rgba(255, 255, 255, 0), white); }
  .circulaire-economie__main::after {
    content: '';
    display: block;
    width: calc(100% - 20px);
    height: 100px;
    z-index: 1;
    position: absolute;
    bottom: 0;
    left: 0;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), white); }

.circulaire-economie__main__inner {
  max-width: 500px;
  padding: 1.25rem;
  margin: auto;
  position: relative;
  z-index: 0;
  height: 100%; }
  .circulaire-economie__main__inner article {
    padding: 40px 0 60px 0; }

.circulaire-economie .slider {
  width: 100%; }
  .circulaire-economie .slider__slide {
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; }

@media screen and (max-width: 1440px) {
  .circulaire-economie__main__inner article {
    padding-top: 20px; } }

@media screen and (max-width: 1100px) {
  .circulaire-economie__main__inner article {
    padding-top: 0;
    padding-bottom: 20px; }
  .circulaire-economie__main::before {
    height: 50px; }
  .circulaire-economie__main::after {
    height: 50px; } }

@media screen and (max-width: 990px) {
  .circulaire-economie {
    height: auto;
    min-height: 0;
    max-height: 100%; }
    .circulaire-economie__inner {
      min-height: auto;
      flex-direction: column; }
    .circulaire-economie__main, .circulaire-economie__visual {
      width: 100%; }
    .circulaire-economie__visual {
      order: 2; }
    .circulaire-economie__main {
      order: 1; }
      .circulaire-economie__main::before, .circulaire-economie__main::after {
        display: none; }
    .circulaire-economie__main__inner {
      max-width: 100%; }
      .circulaire-economie__main__inner article {
        padding: 0; }
    .circulaire-economie .slider__slide {
      height: 370px;
      min-height: 0;
      max-height: 100%; } }

.future {
  position: relative;
  background-color: #15dcbe;
  background-image: linear-gradient(to bottom, #15dcbe, #5fceea);
  color: #fff;
  padding: 4.375rem 0; }
  .future__inner {
    position: relative; }
  .future__title {
    color: inherit;
    margin: 0 0 3.75rem 0; }
  .future__intro {
    font-size: 1.25rem;
    line-height: 1.6; }
  .future__content {
    width: calc(100% - 400px); }
  .future .stacked-logo {
    margin-top: 20px; }

.future__view {
  background-color: #fff;
  background-image: url("../img/icn-wheel.svg");
  background-position: right 20px;
  background-repeat: no-repeat;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
  color: #444;
  padding: 30px;
  position: absolute;
  top: 0;
  right: 20px;
  bottom: -10.625rem;
  border-radius: 4px 4px 0 0;
  width: 380px; }
  .future__view__title {
    font-weight: 700;
    color: #15dcbe;
    font-size: 1.5rem; }
    .future__view__title a {
      color: inherit;
      text-decoration: none; }
  .future__view__subtitle {
    font-weight: 400;
    font-size: 1.25rem; }
  .future__view p {
    font-size: 1rem; }

button.future__view__slider__button {
  display: block;
  font-size: 1rem;
  padding: 1.25rem 0 0;
  margin-top: 0.625rem;
  width: 100%;
  border-top: 1px solid #15dcbe;
  text-align: left;
  outline: none;
  display: flex; }
  button.future__view__slider__button span {
    flex-grow: 1; }
  button.future__view__slider__button .icn {
    width: auto;
    height: auto; }

@media screen and (max-width: 990px) {
  .future {
    padding-bottom: 0; }
  .future__content {
    width: auto; }
  .future__view {
    box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.2);
    position: relative;
    width: auto;
    left: auto;
    right: auto;
    margin-top: 2.5rem; } }

.network {
  padding-bottom: 5rem; }
  .network__title {
    margin: 2.5rem 0; }
  .network__cta {
    margin-top: 5rem;
    text-align: center; }

.network__visual {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; }

.network__grid {
  position: relative;
  margin-top: -2.8125rem;
  display: flex;
  justify-content: space-between; }
  .network__grid__item {
    width: calc(33.333% - 20px); }

.network__grid__item .network__visual {
  display: none;
  height: 320px; }

.network-slider .network__visual {
  height: 0;
  padding-bottom: 40%;
  background-position: center 30%; }
  .network-slider .network__visual[data-slide="3"] {
    background-position: center 55%; }

@media screen and (max-width: 990px) {
  .network {
    padding-bottom: 2.5rem; }
  .network-slider {
    display: none; }
  .network__grid__item .network__visual {
    display: block; }
  .network__grid {
    display: block;
    margin-top: 2.5rem;
    margin-left: 0;
    margin-right: 0;
    padding: 0; }
  .network__grid__item {
    width: auto;
    margin-bottom: 2.5rem; }
    .network__grid__item .card {
      margin: -3.75rem 0.625rem 0; }
  .network__cta {
    margin-top: 2.5rem; } }

.pillars {
  position: relative; }
  .pillars__intro {
    height: 610px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
    overflow: hidden; }
    @media screen and (max-width: 990px) {
      .pillars__intro {
        height: auto;
        padding: 2.5rem 0; } }
    .pillars__intro::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(88, 125, 131, 0.8);
      z-index: 2; }
    .pillars__intro__inner {
      height: 100%;
      display: flex;
      align-items: center;
      position: relative;
      z-index: 3; }
  .pillars__intro__content p {
    max-width: 400px;
    color: #fff;
    font-size: 1.25rem;
    line-height: 1.6;
    margin: 0; }
  .pillars__intro__title {
    margin-top: 0; }

.pillars__inner {
  position: relative; }

.pillars__content {
  color: #fff;
  position: relative;
  margin: 0; }
  @media screen and (max-width: 990px) {
    .pillars__content {
      background-color: #15dcbe;
      background-image: linear-gradient(to top, #15dcbe, #5fceea);
      padding: 40px 0; } }
  .pillars__content__bg {
    background-color: #15dcbe;
    background-image: linear-gradient(to top, #15dcbe, #5fceea);
    position: absolute;
    width: 100%;
    height: 100vh;
    z-index: 0; }
    @media screen and (max-width: 990px) {
      .pillars__content__bg {
        height: auto;
        display: none; } }
  .pillars__content__title {
    font-size: 2rem;
    line-height: 1.25;
    color: #fff;
    margin: 0; }

.pillars__visuals {
  width: 50%;
  height: 100vh;
  position: absolute;
  right: 0; }
  @media screen and (max-width: 990px) {
    .pillars__visuals {
      display: none; } }

.pillar {
  height: 100vh;
  position: relative;
  z-index: 1; }
  @media screen and (max-width: 990px) {
    .pillar {
      height: auto; } }
  @media screen and (min-width: 990px) {
    .pillar--1 {
      padding-top: 50vh; } }
  .pillar__inner {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center; }
  .pillar__content {
    width: 50%;
    max-width: 400px;
    margin-left: 0 !important;
    transform-origin: left top; }
    @media screen and (max-width: 990px) {
      .pillar__content {
        width: 100%; } }
  .pillar__title {
    font-size: 2rem;
    line-height: 1.25;
    margin: 0 0 40px;
    font-weight: 400;
    color: #fff; }
    .pillar__title span {
      font-weight: 700; }
    @media screen and (max-width: 990px) {
      .pillar__title {
        font-size: 1.75rem;
        line-height: 1.35714;
        margin: 1.3em 0 0; } }
    @media screen and (max-width: 480px) {
      .pillar__title {
        font-size: 1.375rem;
        line-height: 1.45455; } }

.pillar__visual {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: opacity 1s ease-in-out;
  opacity: 0; }
  .pillar__visual.is-active {
    opacity: 1; }

@media screen and (min-width: 990px) {
  .pillars__content__header {
    width: 100%;
    position: absolute;
    z-index: 2; } }

.pillars__content__title {
  color: #fff;
  margin: 0; }
  @media screen and (max-width: 990px) {
    .pillars__content__title {
      font-size: 1.75rem; } }
  @media screen and (max-width: 480px) {
    .pillars__content__title {
      font-size: 1.375rem; } }

.pillars__pager {
  display: none; }
  @media screen and (min-width: 990px) {
    .pillars__pager {
      display: block;
      position: absolute;
      z-index: 2;
      height: 100vh;
      width: 100%;
      z-index: 1; } }

.recycling__grid {
  display: flex;
  justify-content: space-between;
  padding-top: 5rem; }

.recycling__inner {
  background-color: #fafafa;
  overflow: hidden; }

.recycling__block {
  width: 15rem;
  position: relative;
  padding-bottom: 8.75rem; }
  .recycling__block:nth-child(2) {
    margin-top: 6.25rem; }
  .recycling__block:nth-child(3) {
    margin-top: 3.75rem; }

.recycling__block__content {
  padding-right: 40px; }

.recycling__block__visual {
  position: absolute;
  right: -40px;
  margin-top: -2em; }

.recycling .icn--washing-machine {
  width: 142px;
  height: 125px; }

.recycling .icn--recycling {
  width: 88px;
  height: 93px; }

.recycling .icn--target {
  width: 116px;
  height: 124px; }

@media screen and (max-width: 990px) {
  .recycling__grid {
    flex-direction: column;
    align-items: center; }
  .recycling__block {
    margin-top: 0; }
    .recycling__block:nth-child(2) {
      margin-top: 0;
      padding-bottom: 80px; }
    .recycling__block:nth-child(3) {
      margin-top: 0; } }

@media screen and (max-width: 600px) {
  .recycling__block {
    width: auto; }
  .recycling__block__visual {
    right: 0;
    margin-top: auto; } }

.results {
  padding: 5rem 0;
  background-color: #fafafa;
  background-image: url("../img/icn-stack-light.svg");
  background-position: calc(50% - 300px) 80px;
  background-repeat: no-repeat; }

.results__intro__title {
  margin-top: 0; }

.results__intro__content {
  max-width: 49.375rem; }
  .results__intro__content p {
    font-size: 1.25rem;
    line-height: 1.6; }

.results__section {
  margin: 0 0 3.75rem 0; }

.results__subtitle {
  font-size: 1.5rem;
  color: #0078ae;
  margin-top: 0;
  font-weight: 400;
  position: relative; }
  .results__data .results__subtitle .icn {
    position: absolute;
    top: 9px;
    left: -30px; }
  @media screen and (max-width: 1230px) {
    .results__data .results__subtitle {
      padding-left: 30px; }
      .results__data .results__subtitle .icn {
        left: 0; } }

.results__grid {
  display: flex;
  justify-content: space-between; }
  @media screen and (max-width: 990px) {
    .results__grid {
      display: block; } }

.results__data,
.results__how {
  width: calc(50% - 10px); }
  @media screen and (max-width: 990px) {
    .results__data,
    .results__how {
      width: 100%; } }

.results__outro {
  text-align: center;
  padding: 0 1.875rem; }
  .results__outro p {
    font-size: 1.5rem;
    color: #0078ae;
    font-weight: 700;
    margin: 5rem 0 2.5rem; }
    @media screen and (max-width: 768px) {
      .results__outro p {
        font-size: 1.25rem; } }

.result__detail {
  display: flex;
  justify-content: space-between; }

.result__data {
  width: calc(50% - 10px); }
  .results__section--1 .result__data.result__data--2016 {
    width: calc(45% - 10px); }
  .results__section--1 .result__data.result__data--2017 {
    width: calc(55% - 10px); }
  .results__section--2 .result__data.result__data--2016 {
    width: calc(60% - 10px); }
  .results__section--2 .result__data.result__data--2017 {
    width: calc(40% - 10px); }
  .results__section--3 .result__data.result__data--2016 {
    width: calc(45% - 10px); }
  .results__section--3 .result__data.result__data--2017 {
    width: calc(55% - 10px); }

@media screen and (max-width: 480px) {
  .result__detail {
    display: block; }
  .result__data + .result__data {
    margin-top: 20px; }
  .results__section--1 .result__data.result__data--2016 {
    width: 100%; }
  .results__section--1 .result__data.result__data--2017 {
    width: 100%; }
  .results__section--2 .result__data.result__data--2016 {
    width: 100%; }
  .results__section--2 .result__data.result__data--2017 {
    width: 100%; }
  .results__section--3 .result__data.result__data--2016 {
    width: 100%; }
  .results__section--3 .result__data.result__data--2017 {
    width: 100%; } }

.result__figure {
  font-size: 1.375rem; }
  @media screen and (min-width: 1280px) {
    .result__figure {
      font-size: 1.75rem; } }
  .result__data--2016 .result__figure {
    color: #5fceea; }
  .result__data--2017 .result__figure {
    color: #15dcbe; }
  .result__figure__number {
    font-size: 2em; }

.result__date {
  height: 2.875rem;
  border-radius: 4px;
  background-color: #5fceea;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
  color: #0078ae;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  padding-left: 1rem;
  margin: 1.25rem 0 0; }
  .result__data--2017 .result__date {
    background-image: linear-gradient(to right, #15dcbe, #5fceea); }

.timeline__content:after {
  display: table;
  clear: both;
  content: ''; }

.timeline__detail {
  float: left;
  position: relative;
  width: 0;
  transition-property: width;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out; }
  .timeline__detail__inner {
    position: relative;
    max-width: 480px;
    padding: 0 1.25rem;
    margin: auto; }

.timeline__overview {
  float: right;
  width: 100%;
  ransition-property: width;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out; }

.timeline__masonry {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  transition-property: width;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out; }

.timeline.is-open .timeline__masonry {
  transition-duration: 0s; }

.timeline__title {
  position: relative;
  text-align: center;
  margin: 0;
  padding: 2rem 0;
  background-color: #fff;
  z-index: 2; }
  .timeline__title.is-sticky {
    position: fixed; }

.timeline__block {
  transition-property: opacity, left, top, width, transform;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out; }
  .timeline__block.is-hidden {
    display: none; }

.timeline .breakout {
  position: absolute;
  opacity: 0; }

.timeline .span-1 {
  width: 25%;
  overflow: hidden; }

.timeline .span-2 {
  width: 50%;
  overflow: hidden; }

@media screen and (max-width: 990px) {
  .timeline .span-1,
  .timeline .span-2 {
    width: 100%; } }

@media screen and (min-width: 990px) {
  .timeline__detail {
    min-height: 100%; }
  .timeline__overview {
    height: calc(100vh - 168px);
    position: relative; }
  .timeline__masonry {
    height: 100%;
    position: relative; }
  .timeline__block {
    height: 50%; }
  .timeline.is-open .span-1,
  .timeline.is-open .span-2 {
    width: 50% !important; }
  .timeline.is-open .timeline__detail {
    width: 50%; }
  .timeline.is-open .timeline__overview {
    width: 50%; }
  .timeline.is-open .timeline__block {
    height: 33.333%; } }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.slick-arrow {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 50%;
  margin-top: -15px;
  z-index: 9;
  overflow: hidden;
  color: #fff;
  font-size: 1.5rem;
  outline: none; }
  .slick-arrow::before {
    content: '';
    display: block;
    text-align: center;
    line-height: 30px;
    width: 100%;
    height: 100%; }

.slick-prev {
  left: 20px; }
  .slick-prev::before {
    content: '\f104'; }

.slick-next {
  right: 20px; }
  .slick-next::before {
    content: '\f105'; }

.slick-dots button {
  outline: none; }

.table {
  margin: 1rem 0;
  border-collapse: collapse;
  font-size: 0.875rem; }
  .table th, .table td {
    padding: 1em;
    border: 1px solid #5fceea; }
  .table__key {
    text-align: left; }
  .table__value {
    text-align: right; }
  .table__head th, .table__head td {
    white-space: nowrap;
    background-color: #5fceea;
    color: #fff;
    border-width: 0 1px 0 0;
    border-color: rgba(255, 255, 255, 0.5); }
    .table__head th:last-child, .table__head td:last-child {
      border-color: #5fceea; }
  .table__highlight th, .table__highlight td {
    background-color: #effafd; }
  .table__highlight .table__value {
    font-weight: 700; }
  .table__total th, .table__total td {
    font-weight: 700;
    background-color: #d0f1f9;
    color: #1aa6c9; }

.table.responsive {
  display: block;
  width: 100%;
  position: relative; }
  .table.responsive .table__body {
    display: block;
    overflow-x: scroll; }
  .table.responsive .table__key {
    position: absolute;
    left: 0; }
  .table.responsive .table__key,
  .table.responsive .table__value {
    white-space: normal;
    border-bottom-width: 0; }
    .table.responsive .table__key[colspan="7"],
    .table.responsive .table__value[colspan="7"] {
      width: 840px; }
  .table.responsive tr:last-child .table__key,
  .table.responsive tr:last-child .table__value {
    border-bottom-width: 1px; }
  .table.responsive .table__key {
    box-shadow: 2px 0 1px rgba(95, 206, 234, 0.4); }
  .table.responsive .table__value {
    border-left-width: 0; }
  .table.responsive tr {
    display: block;
    white-space: nowrap; }
    .table.responsive tr:after {
      display: table;
      clear: both;
      content: ''; }
  .table.responsive .table__row .table__key {
    background-color: #fff; }

.table-legend {
  color: #587d83;
  display: flex;
  align-items: center; }
  .table-legend.is-hidden {
    display: none; }
  .table-legend__text {
    font-size: 0.75rem;
    display: block;
    flex-grow: 1;
    text-align: left;
    opacity: .6;
    padding-right: 10px; }
  .table-legend__icn {
    display: block;
    color: #15dcbe; }
  .table-legend .icn {
    width: auto;
    height: auto;
    font-size: 1.5rem;
    margin-top: 3px; }
  .table-legend .icn + .icn {
    margin-left: 10px; }

@media screen and (max-width: 1200px) {
  .table {
    font-size: 0.75rem; } }

@media screen and (max-width: 960px) {
  .table {
    font-size: 0.75rem; } }

video.video-bg {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -100;
  transform: translateX(-50%) translateY(-50%);
  z-index: 1;
  object-fit: cover; }

.reveal-desktop.will-fade,
.reveal-mobile.will-fade {
  position: relative;
  transition: all 1s ease-in-out;
  opacity: 0;
  transform: translateY(100px); }

.reveal-desktop.is-shown,
.reveal-mobile.is-shown {
  opacity: 1;
  transform: translateY(0); }
