h1, h2 {
  // margin: rem(32) 0;
  font-size: rem(32);
  line-height: lh(40,32);
  font-weight: 700;
  color: $mint;

  .board &,
  .balance & {
    margin: 1.5em 0;
  }

  @include below($tablet) {
    font-size: rem(26);
    line-height: lh(36,26);
  }

  @include below($mobile) {
    font-size: rem(24);
    line-height: lh(32,24);
  }

}

h3, h4, h5 {
  color: $smoke;
}

h3 {
  // margin: 1em 0;
  font-size: rem(20);
  line-height: lh(32,20);
  font-weight: 400;
  color: $smoke;
}


h1.site-title {
  font-size: rem(24);
  margin: 0;
  font-weight: 700;
  color: $smoke;
}


p {
  font-size: rem(16);
  line-height: lh(24,16);
}
