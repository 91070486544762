$zindex: 99;

.offscreen-menu-overlay {
  background-color: rgba(51,51,51,.8);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  z-index: -($zindex);
  will-change: z-index, opacity;
  transition-property: z-index, opacity;
  transition-duration: $base-transition-duration;
  transition-delay: $base-transition-duration, 0s;
  transition-timing-function: $base-transition-easing;
}

.offscreen-menu {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: $zindex;
  transform: translateX(100%);
  will-change: transform;
  transition-property: transform;
  transition-duration: $base-transition-duration;
  transition-timing-function: $base-transition-easing;

  &__inner {
    width: 360px;
    max-width: 100%;
    background-color: $white;
    box-shadow: 0 0 0 0 rgba(51,51,51,.50);
    padding: 40px 85px 50px 40px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    z-index: 1;

    will-change: box-whadow;
    transition-property: transform;
    transition-duration: $base-transition-duration;
    transition-timing-function: $base-transition-easing;
  }
}


.show-menu {

  .offscreen-menu-overlay {
    opacity: 1;
    z-index: ($zindex - 1);
    transition-delay: 0s, .1s;
  }

  .offscreen-menu {
    transform: translateX(0);

    &__inner {
      box-shadow: -10px 0 8px 0 rgba(51,51,51,.50);
    }
  }
}


@include below($tablet) {
  .offscreen-menu__inner {
    width: auto;
    padding: 30px;
  }
}
