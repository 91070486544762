.nav--secondary {
  .nav__item {
    font-size: rem(16);
    color: rgb(163,163,163);
    line-height: lh(36, 16);

    a {
      display: block;
      color: inherit;
    }

    &:hover,
    &:focus,
    &.is-active {
      color: $mint;
    }
  }

  .nav__item + .nav__item {
    border-top: 1px solid rgb(238,238,238);
  }
}
