.reveal-desktop,
.reveal-mobile {

  &.will-fade {
    position: relative;
    transition: all 1s ease-in-out;
    opacity: 0;
    transform: translateY(100px);
  }

  &.is-shown {
    opacity: 1;
    transform: translateY(0);
  }
}
