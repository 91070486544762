// ==========================================================================
// Fonts
// ==========================================================================
// Font-weights
// Thin (Hairline): 100
// Extra Light (Ultra Light): 200
// Light: 300
// Normal: 400
// Medium: 500
// Semi Bold (Demi Bold): 600
// Bold: 700
// Extra Bold (Ultra Bold): 800
// Black (Heavy): 900
// ==========================================================================

$base-font-path: if(variable-exists(base-font-path), $base-font-path, "../fonts/");

$fonts: (
  'Icomoon': (
    (400, normal, "icomoon/icomoon"),
  ),
  'Lato': (
    (100, normal, "lato/lato-hairline-webfont"),
    (100, italic, "lato/lato-hairlineitalic-webfont"),
    (300, normal, "lato/lato-light-webfont"),
    (300, italic, "lato/lato-lightitalic-webfont"),
    (400, normal, "lato/lato-regular-webfont"),
    (400, italic, "lato/lato-italic-webfont"),
    (500, normal, "lato/lato-medium-webfont"),
    (500, italic, "lato/lato-mediumitalic-webfont"),
    (600, normal, "lato/lato-semibold-webfont"),
    (600, italic, "lato/lato-semibolditalic-webfont"),
    (700, normal, "lato/lato-bold-webfont"),
    (700, italic, "lato/lato-bolditalic-webfont"),
    (800, normal, "lato/lato-heavy-webfont"),
    (800, italic, "lato/lato-heavyitalic-webfont"),
    (900, normal, "lato/lato-black-webfont"),
    (900, italic, "lato/lato-blackitalic-webfont")
  )
);




@if(variable-exists(fonts)) {
  @each $name, $properties in $fonts {
    @each $weight, $style, $path in $properties {

      @font-face {
        font-family: $name;
        font-weight: $weight;
        font-style: $style;
        src: url($base-font-path + $path + ".woff2") format("woff2"),
             url($base-font-path + $path + ".woff") format("woff");
      }

    }
  }
}
