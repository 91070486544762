.slick-arrow {
  @include size(30px);
  position: absolute;
  top: 50%;
  margin-top: -15px;
  z-index: 9;
  overflow: hidden;
  color: $white;
  font-size: rem(24);
  outline: none;

  &::before {
    @extend %icn-font;
    content: '';
    display: block;
    text-align: center;
    line-height: 30px;
    width: 100%;
    height: 100%;
  }
}

.slick-prev {
  left: 20px;

  &::before { content: '\f104' }
}

.slick-next {
  right: 20px;

  &::before { content: '\f105' }
}

.slick-dots button {
  outline: none;
}
