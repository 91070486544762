.departments {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.department {
  text-align: center;
  width: 220px;
  margin-bottom: rem(60);

  &__header {
    margin-bottom: 10px;
  }

  &__header__inner {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__title {
    font-size: rem(20);
    font-weight: 400;
    color: $blue;
    margin: 0 0 0 10px;
  }

  &__icon {
    width: 30px;
    height: 30px;

    .icn {
      width: 100%;
      height: 100%;
    }
  }

}


.members {
  display: flex;
  flex-wrap: wrap;
}

.member {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  &__avatar
  &__name,
  &__function,
  &__department {
    display: block;
  }

  &__avatar {
    width: 100px;
    height: 100px;
    background-size: cover;
    background-position: center;
  }

  &__name {
    font-size: rem(14);
    font-weight: 700;
    margin: 1em 0 0;
    color: $blue-light;
  }

  &__function,
  &__department {
    font-size: rem(12);
    color: rgba(#000, .5);
    margin: .2em 0 0 0;
  }
}
