.recycling {

  &__grid{
    display: flex;
    justify-content: space-between;
    padding-top: rem(80);
  }

  &__inner {
    background-color: #fafafa;
    overflow: hidden;
  }

  &__block {
    width: rem(240);
    position: relative;
    padding-bottom: rem(140);

    &:nth-child(2) { margin-top: rem(100); }
    &:nth-child(3) { margin-top: rem(60); }
  }

  &__block__content {
    padding-right: 40px;
  }

  &__block__visual {
    position: absolute;
    right: -40px;
    margin-top: -2em;
  }

  .icn {
    &--washing-machine { @include size(142px, 125px) }
    &--recycling       { @include size(88px, 93px) }
    &--target          { @include size(116px, 124px) }
  }

}


@include below($tablet) {
  .recycling__grid {
    flex-direction: column;
    align-items: center;
  }

  .recycling__block {
    margin-top: 0;
    &:nth-child(2) { margin-top: 0; padding-bottom: 80px }
    &:nth-child(3) { margin-top: 0; }
  }
}


@include below($tablet-xs) {
  .recycling__block {
    width: auto;
  }
  .recycling__block__visual {
    right: 0;
    margin-top: auto;
  }
}
