.results {
  padding: rem(80) 0;
  background-color: #fafafa;
  background-image: url('../img/icn-stack-light.svg');
  background-position: calc(50% - 300px) 80px;
  background-repeat: no-repeat;
}

.results__inner {

}

.results__intro {

}

.results__intro__title {
  margin-top: 0;
}

.results__intro__content {
  max-width: rem(790);

  p {
    font-size: rem(20);
    line-height: lh(32,20);
  }
}

.results__section {
  margin: 0 0 rem(60) 0;
}

.results__subtitle {
  font-size: rem(24);
  color: $blue;
  margin-top: 0;
  font-weight: 400;
  position: relative;

  .results__data & {
    .icn {
      position: absolute;
      top: 9px;
      left: -30px;
    }

    @include below(1230px) {
      padding-left: 30px;
      .icn {
        left:0;
      }
    }
  }

}


.results__grid {
  display: flex;
  justify-content: space-between;

  @include below($tablet) {
    display: block;
  }
}

.results__data,
.results__how {
  width: calc(50% - 10px);

  @include below($tablet) {
    width: 100%;
  }
}

.results__outro {
  text-align: center;
  padding: 0 rem(30);
  p {
    font-size: rem(24);
    color: $blue;
    font-weight: 700;
    margin: rem(80) 0 rem(40);

    @include below($tablet-sm) {
      font-size: rem(20);
    }
  }
}

.result__detail {
  display: flex;
  justify-content: space-between;
}


.result__data {
  width: calc(50% - 10px);

  .results__section--1 & {
    &.result__data--2016 { width: calc(45% - 10px); }
    &.result__data--2017 { width: calc(55% - 10px); }
  }

  .results__section--2 & {
    &.result__data--2016 { width: calc(60% - 10px); }
    &.result__data--2017 { width: calc(40% - 10px); }
  }

  .results__section--3 & {
    &.result__data--2016 { width: calc(45% - 10px); }
    &.result__data--2017 { width: calc(55% - 10px); }
  }
}

@include below($mobile) {
  .result__detail {
    display: block;
  }

  .result__data {
    & + & {
      margin-top: 20px;
    }
    .results__section--1 & {
      &.result__data--2016 { width: 100%; }
      &.result__data--2017 { width: 100%; }
    }

    .results__section--2 & {
      &.result__data--2016 { width: 100%; }
      &.result__data--2017 { width: 100%; }
    }

    .results__section--3 & {
      &.result__data--2016 { width: 100%; }
      &.result__data--2017 { width: 100%; }
    }
  }
}

.result__figure {
  font-size: rem(22);

  @include above($desktop-md) {
    font-size: rem(28);
  }

  .result__data--2016 & {
    color: $blue-light;
  }

  .result__data--2017 & {
    color: $mint;
  }

  &__number {
    font-size: 2em;
  }

}

.result__date {
  height: rem(46);
  border-radius: 4px;
  background-color: $blue-light;
  box-shadow: 0 2px 8px 0 rgba(#000,.20);
  color: $blue;
  font-size: rem(14);
  display: flex;
  align-items: center;
  padding-left: rem(16);
  margin: rem(20) 0 0;

  .result__data--2017 & {
    background-image: $gradient-mint;
  }
}





