.event {

  @include above($tablet) {
    height: 100%;
    border-top: 1px solid $white;
  }

  &__teaser {
    height: rem(328);
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;

    @include above($tablet) {
      height: 100%;
    }

    @include below($tablet) {
      transition-property: height;
      transition-duration: $base-transition-duration;
      transition-timing-function: $base-transition-easing;
    }


    // @include above($tablet) {
    //   height: 100%;
    // }

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: red;
      z-index: 0;
      opacity: .9;
      transition-property: height;
      transition-duration: $base-transition-duration;
      transition-timing-function: $base-transition-easing;
    }
  }

  &__teaser__inner {
    position: relative;
    z-index: 1;
    height: 100%;
  }

  &__teaser__title {
    position: absolute;
    margin: 0;
    color: $white;
    font-weight: 700;
    font-size: rem(20);
    line-height: lh(32,20);
    margin: 0;
    top: 0;
    padding: 50px 30px 30px;
    transition-property: all;
    transition-duration: $base-transition-duration;
    transition-timing-function: $base-transition-easing;
  }

  &__indicator {
    height: 100%;
    position: absolute;
    transition-property: height;
    transition-duration: $base-transition-duration;
    transition-timing-function: $base-transition-easing;
    bottom: 0;
    left: 10px;
    right: 0;

    &::before {
      content: '';
      display: block;
      width: 1px;
      background-color: $white;
      position: absolute;
      top: 0;
      bottom: 0;
    }
  }

  &__date {
    position: absolute;
    color: $white;
    left: 20px;
    top: 20px;
    transition: top .3s ease-in-out;

    &::before {
      content: '';
      display: block;
      width: 14px;
      height: 1px;
      background-color: $white;
      position: absolute;
      top: 50%;
      left: -20px;

      @include above($tablet) {
        display: none;
      }
    }
  }

}


.event__next {
  margin-top: rem(40);
  border-top: 1px solid $mint;
  padding: rem(20) 0 0;
  font-size: rem(16);
  line-height: lh(24, 16);
  display: flex;
  align-items: center;

  @include above($tablet) {
    cursor: pointer;
  }

  @include below($tablet) {
    display: none;
  }

  &__inner {
    flex-grow: 1;
    padding-right: 60px;
  }

  .icn {
    width: auto;
    height: auto;
  }

  &__date {
    color: $mint;
    font-size: inherit;
    font-weight: 700;
  }

  &__title {
    margin: 0;
    font-size: inherit;
    font-weight: 400;
    br {
      display: none;
    }
  }
}


// EVENT IS ACTIVE
.event.is-active {
  .event__teaser::before {
    height: 0;
  }

  .event__date {
    top: calc(100% - 40px);
  }

  .event__teaser__title {
    top: 100%;
    opacity: 0;
  }
}


.event__content {

  &__close {
    position: absolute;
    width: 20px;
    height: 20px;
    right: 30px;
    z-index: 1;

    .icn {
      width: 100%;
      height: 100%;
    }

    @include above($tablet) {
      overflow-y: scroll;
    }

    @include below($tablet) {
      display: none;
    }
  }

  &__inner {
    padding: 0 30px;
    padding-bottom: 30px;
    max-width: 700px;
    margin: auto;

    @include below($tablet) {
      padding: 30px;
    }

    h2 {
      margin-top: 0;

      @include below($hd) {
        font-size: rem(26);
        line-height: lh(30, 26);
      }

      @include below($desktop) {
        font-size: rem(24);
        line-height: lh(30, 24);
      }
    }

    p {
      margin: .5em 0 0 0;
      @include below($hd) {
        font-size: rem(15);
      }
    }
  }

  &__visual {
    height: 0;
    padding-bottom: 35%;
    margin: rem(20) 0;
    background-position: center 15%;
    background-size: cover;

    @include below($desktop-hd) {
      padding-bottom: 30%;
      background-position: center 10%;
    }

    // @include below($desktop) {
      // padding-bottom: 30%;
      // background-position: center 10%;
      // display: none;
    // }

    @include below($tablet) {
      display: none;
    }
  }

  &__date {
    color: $mint;
    font-size: rem(16);
    font-weight: 700;

    @include below($hd) {
      font-size: rem(14);
    }

    @include below($tablet) {
      display: none;
    }
  }


}



// Colors
.event {
  &[data-color="blue"] {
    .event__teaser::before {
      background-color: $blue
    }
  }

  &[data-color="blue-light"] {
    .event__teaser::before {
      background-color: $blue-light
    }
  }

  &[data-color="smoke"] {
    .event__teaser::before {
      background-color: $smoke
    }
  }

  &[data-color="mint"] {
    .event__teaser::before {
      background-color: $mint
    }
  }
}


@include above($tablet) {
  .event {

    &__teaser__title {
      padding: 30px;
      position: absolute;
    }

    &__indicator {
      height: 80px;
    }

    &__content__inner {
      display: flex;
      flex-direction: column;

      &__copy {
        flex-grow: 1;
      }
    }

  }

  .timeline {
    .event__teaser__title {
      font-size: rem(24);
      line-height: lh(40,24);
    }

    .event[data-row="1"] {
      .event__inner::before {
        bottom: 0;
        top: auto;
      }

      .event__teaser__title {
        top: 0;
        bottom: auto;
      }

      .event__indicator {
        bottom: 0;
        top: auto;
        left: rem(30);
      }

      .event__date {
        top: 0;
        bottom: auto;

        &::before {
          display: none;
        }
      }
    }

    .event[data-row="2"] {
      .event__inner::before {
        top: 0;
        bottom: auto;
      }

      .event__teaser__title {
        bottom: 0;
        top: auto;
      }

      .event__indicator {
        top: 0;
        bottom: auto;
        left: rem(80);
      }

      .event__date {
        bottom: 0;
        top: auto;
      }
    }

  }

    // hover
  .event:hover {
    cursor: pointer;

    .event__inner::before {
      height: 0;
    }

    .event__indicator {
      height: rem(100);
    }
  }

  // TIMELINE IS OPEN
  .timeline.is-open {
    .timeline__block.span-2 { width: 25%; }

    .event__teaser__title {
      font-size: rem(18);
      line-height: lh(24,18);
    }

    .event__teaser {
      // @include above($tablet) {
      //   height: 170px;
      // }
    }

    .event[data-row="1"],
    .event[data-row="2"] {
      .event__teaser__title {
        bottom: auto;
        top: 0;
      }

      .event__indicator {
        bottom: 0;
        top: auto;
        height: rem(40);
        left: rem(30);
      }

      .event__date {
        bottom: auto;
        top: 0;
        font-size: rem(14);
      }

      &:hover {
        .event__indicator {
          height: rem(50);
        }
      }
    }

  }



  @media (max-height: 700px), (max-width: 1100px) {
    .event__content__inner p {
      font-size: rem(14);
    }

    .timeline {
      .event__teaser__title {
        font-size: rem(20);
        line-height: 1.2;
      }

      .event__date {
        font-size: rem(12);
      }

    }

    .timeline.is-open {
      .event__teaser__title {
        font-size: rem(16);
        line-height: 1.2;
      }
    }

    .timeline, .timeline.is-open {
      .event[data-row="1"],
      .event[data-row="2"] {
        .event__indicator {
          height: 30px;
        }

        &:hover {
          .event__indicator {
            height: 40px;
          }
        }
      }
    }

  }
}



