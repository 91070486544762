$header-height: 64px;

.header {
  height: $header-height;
  box-shadow: 0 1px 1px rgba(222,222,222,.5);
  z-index: 9;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: $white;

  &__inner {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 rem(20);
  }

  &__logo {
    width: 135px;
    height: 39px;
  }

  &__title {
    flex-grow: 1;
    padding-left: 20px;
  }

}

.header-placeholder {
  height: $header-height;
}



// @include below($tablet-xs) {
//   .header__logo {
//     overflow: hidden;
//     position: relative;
//     text-align: right;
//     transition: width .3s ease-in-out;

//     .logo {
//       position: absolute;
//       top: 0;
//       right: 0;
//     }
//   }

//   .show-menu .header__logo { width: 42px; }
// }


@include below($tablet) {
  .header__inner {
    justify-content: space-between;
  }

  .header__language,
  .header__title {
    display: none;
  }
}
