.cards-grid {
  // @include clearfix();
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  &--2 {
    .card {
      width: calc(50% - 20px);
    }
  }

  &--3 {
    .card {
      width: calc(33.333% - 20px);
    }
  }

  .network & {
    align-items: stretch;
    max-width: 1380px;
  }
}




.card {
  background: $white;
  box-shadow: 0 2px 8px 0 rgba(#000,.20);
  border-radius: 4px;
  padding: rem(30);
  position: relative;

  p {
    margin: 1em 0 0;
  }

  &__title {
    margin: 0;
    font-weight: 400;
    font-size: rem(20);
  }

  &__title-lrg {
    margin: 0;
    font-size: rem(24);
    color: $blue;
  }

  &__meta {
    display: flex;
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #E0E0E0;

    &__detail {
      flex-grow: 1;

      &:nth-child(odd) {
        color: $blue-light;
      }

      &:nth-child(even) {
        color: $mint;
      }
    }

    &__detail + &__detal {
      width: 50%;
    }

    &__result,
    &__title {
      display: block;
    }

    &__title {
      font-weight: 700;
    }

    &__result {
      font-weight: 300;
      font-size: rem(40);
      line-height: 1;
      margin-top: .3em;
    }
  }
}

.card--lrg {
  // padding: rem(60) rem(30) rem(30);
  padding: rem(30);
  margin-top: rem(30);

  .card__visual {
    position: absolute;
    top: rem(10);
    right: rem(30);
    z-index: 0;
    opacity: .3;
  }

  .card__content {
    position: relative;
    z-index: 1;
  }

  .card__count {
    font-weight: 400;
    font-size: rem(72);
    position: absolute;
    color: $mint;
    top: rem(-50);
    left: rem(22);
  }

  &.card--1 {
    .icn {
      @include size(87px, 112px);
    }
  }

  &.card--2 {
    .icn {
      @include size(65px, 110px);
    }

    .card__count {
      left: rem(30);
    }
  }

  &:nth-of-type(1) {
    .card__visual {
      transform: rotate(90deg);
    }
  }
}

.card {
  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    transition: all .2s ease-in-out;
  }

  &::before {
    margin-left: -37px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 37px 37px 37px;
    border-color: transparent transparent $white transparent;
  }

  &::after {
    @include size(20px);
    opacity: 0;
    margin-left: -10px;
    border-style: solid;
    border-color: $mint;
    border-width: 2px 2px 0 0;
    transform: rotate(-45deg);
  }

  &.is-active {
    &::before,
    &::after {
      opacity: 1;
    }

    &::before {
      top: -37px;
    }

    &::after {
      top: -10px;
    }
  }
}

.network__grid .card__inner {
  overflow: hidden;
}


@include below($tablet-xs) {
  .results__how {
    .card--1,
    .card--2 {
      margin-left: rem(-30);
      margin-right: rem(-30);
      border-radius: 0;
    }

  }

  .cards-grid {
    position: relative;
    display: block;

    &--2 {
      .card {
        width: auto;
      }
    }

    &--3 {
      .card {
        width: auto;
      }
    }

    .network & {
      align-items: stretch;
      max-width: 1380px;
    }
  }
}
