.goals {
  background-color: $mint;
  background-image: $gradient-mint-vertical-rev;
  color: $white;
  height: calc(100vh - 64px);
  min-height: 512px;
  position: relative;


  &__inner {
    position: relative;
    // display: flex;
    // justify-content: flex-end;
    height: 100%;
  }

  &__pager {
    position: absolute;
    width: rem(32);
    top: 50%;
    left: -70px;
  }

  &__content,
  &__visuals {
    // width: 50%;
    height: 100%;
    position: relative;
  }

  &__content {
    position: relative;
    z-index: 1;
  }

  &__visuals {
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
  }

  &__content__inner {
    width: 50%;
    height: 100%;
    position: relative;
  }

  &__content__title {
    font-size: rem(32);
    line-height: lh(40,32);
    color: $white;
    margin: 0;
    position: absolute;
    top: 120px;
  }
}

.goal-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  &__inner {
    // height: 500px;
    overflow-y: scroll;
    position: relative;
  }
}

.goal {
  // position: relative;
  // opacity: 0;
  width: 100%;
  left: 0;
  margin-bottom: 0;
  // top: 240px;
  // transition: all .6s ease-in-out;

  // &.is-active {
  //   opacity: 1;
  //   top: 0;
  // }

  &__title {
    font-size: rem(32);
    line-height: lh(40,32);
    margin: 0 0 40px;
    font-weight: 400;
    color: $white;

    span { font-weight: 700; }
  }

  p {
    max-width: 350px;
    width: 100%;
    margin-bottom: 0;
  }


  &__visual {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    transition: opacity .6s ease-in-out;

    &.is-active {
      opacity: 1;
    }
  }

}

@include below($desktop-md) {
  .goals__pager {
    left: 0;
    transform: translateY(-120px)
  }
}


@include below($tablet) {
  .goals {
    height: auto;
    min-height: 0;
  }

  .goals__inner {
    position: relative;
  }

  .goals__pager {
    margin: 0;
    top: auto;
    left: auto;
    padding: 30px 0 20px;
    position: relative;
    transform: translateX(0);
  }

  .goal-wrapper {
    top: auto;
    margin-bottom: 30px;
    position: relative;
  }

  .goals__content {
    position: relative;
    transform: none;
    top: auto;
    width: auto;
  }

  .goals__content__title {
    margin-top: 0;
    top: auto;
    font-size: rem(24);
    line-height: lh(40,24);
    position: relative;
  }

  .goals__visuals {
    display: none;
  }

  .goals__content__inner {
    width: 100%;
  }

  .goal {
    width: auto;
    right: 30px;
    // left: 30px;
    // top: 220px;

    &__title {
      font-size: rem(24);
      line-height: lh(40,24);
      margin-bottom: 24px;
    }

    // &.is-active {
    //   top: 180px;
    // }
  }
}
