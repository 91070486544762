%icn-font {
  &::before {
    font-family: 'Icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

.icn {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  display: inline-block;
  width: 40px;
  height: 40px;

  @extend %icn-font;

  &--angle-left::before  { content: '\f104'; }
  &--angle-right::before { content: '\f105'; }
  &--angle-up::before    { content: '\f106'; }
  &--angle-down::before  { content: '\f107'; }

  &--svg::before        { display: none; }

  &--close              { background-image: url('../img/icn-close.svg'); }
  &--washing-machine    { background-image: url('../img/icn-washing-machine.svg'); }
  &--recycling          { background-image: url('../img/icn-recycling.svg'); }
  &--target             { background-image: url('../img/icn-target.svg'); }
  &--mixer              { background-image: url('../img/icn-mixer.svg'); }
  &--lightbulb          { background-image: url('../img/icn-lightbulb.svg'); }

  &--BW-REC             { background-image: url('../img/departments/BW-REC.svg'); }
  &--AV                 { background-image: url('../img/departments/AV.svg'); }
  &--SDA                { background-image: url('../img/departments/SDA.svg'); }
  &--ICT                { background-image: url('../img/departments/ICT.svg'); }
  &--ETandG             { background-image: url('../img/departments/ETandG.svg'); }
  &--LIGHTREC           { background-image: url('../img/departments/LIGHTREC.svg'); }
  &--MELAREC            { background-image: url('../img/departments/MELAREC.svg'); }

  &--triangle-up {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 12px 19px 12px;
    border-color: transparent transparent $mint transparent;

    &::before { display: none; }

  }

  &--triangle-down {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 19px 12px 0 12px;
    border-color: $mint transparent transparent transparent;

    &::before { display: none; }
  }
}
