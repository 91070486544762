.pager {
  position: absolute;
  top: 50%;
  margin-left: -30px;
  transform: translate(-100%, -100%);
  font-size: rem(12);
  width: rem(32);
  display: flex;
  flex-direction: column;

  @include below($desktop-md) {
    transform: translate(0, 0);
    top: 10vh;
    margin-left: 0;
  }

  .slick-dots,
  &__dots {
    list-style: none;
    padding: 0;
    margin: 0;
    order: 1;

    li {
      width: 100%;
      height: 4px;
      border-radius: 4px;
      overflow: hidden;
      background-color: $white;
      opacity: .3;
      // cursor: pointer;
      transition: opacity .3s ease-in-out;
      margin-bottom: 8px;

      &::before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
      }

      &:hover { opacity: .6; }

      &.slick-active,
      &.is-active { opacity: 1; }
    }

  }

  &__counter {
    text-align: center;
    margin-top: 12px;
    order: 2;
  }
}

