.btn {

}

.btn--primary {
  font-size: rem(20);
  font-weight: 700;
  background-color: $mint;
  background-image: linear-gradient(to right, $blue-light 0%, $mint 51%, $blue-light 100%);
  background-size: 200% 100%;
  // background-image: $gradient-mint;
  text-decoration: none;
  color: $white;
  display: inline-block;
  padding: 1em 2em;
  border-radius: 4px;
  transition-duration: .5s;
  position: relative;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to right, rgba($white, 0) 0%, rgba($white, 0) 35%, rgba($white, .4) 51%, rgba($white, 0) 65%, rgba($white, 0) 100%);
    background-size: 200% 100%;
    transition: all .3s ease-in-out;
    opacity: 0;
  }

  &:hover {
    &,
    &::before {
      background-position: right center;
      opacity: 1;
    }
  }
}

a.btn--primary  {
  color: $white;
}


.btn--small {
  font-size: rem(16);
  padding: 7px 14px;
}

.btn--outline {
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  outline: 0;
  font-weight: 400;
}
