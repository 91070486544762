.logo {
    display: inline-block;
    width: 135px;
    height: 39px;
    background-color: transparent;
    background-image: url('../img/logo_full_color.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}
