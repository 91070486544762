// ==========================================================================
// Variables
// ==========================================================================

$root-box-sizing: border-box;

$mobile: 480px;
$tablet-xs: 600px;
$tablet-sm: 768px;
$tablet: 990px;
$desktop: 1100px;
$desktop-md: 1280px;
$desktop-hd: 1440px;
$hd: 1650px;
$desktop-xxl: 2000px;

$max-width: $desktop-hd;
$inner-max-width: $desktop;

$base-margin: 20px;

// Colors
$black: #000;
$white: #fff;
$red: #FB3E4B;
$blue: #0078ae;
$blue-light: #5fceea;
$green: #84c92c;
$mint: #15dcbe;
$smoke: #587d83;

$title-color: $blue;
$intro-color: $mint;
$body-color: #444;
$link-color: $green;
$error-color: $red;

$gradient-mint: linear-gradient(to right, $mint, $blue-light);
$gradient-mint-rev: linear-gradient(to left, $mint, $blue-light);
$gradient-mint-vertical: linear-gradient(to bottom, $mint, $blue-light);
$gradient-mint-vertical-rev: linear-gradient(to top, $mint, $blue-light);
$gradient-blue: linear-gradient(to right, $blue-light, $blue);
$gradient-blue-rev: linear-gradient(to left, $blue-light, $blue);

// Paths
$base-path: '../';
$base-font-path: $base-path + 'fonts/';
$base-img-path:  $base-path + 'img/';




// Fonts
$system-fonts: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
$base-font-family: 'Lato', sans-serif;


// Transitions

$base-transition-duration: .3s;
$base-transition-easing: ease-in-out;

$base-transition: 'all .3s ease';
