.network {
  padding-bottom: rem(80);

  &__title {
    margin: rem(40) 0;
  }

  &__cta {
    margin-top: rem(80);
    text-align: center;
  }
}

.network__visual {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.network__grid {
  position: relative;
  margin-top: rem(-45);
  display: flex;
  justify-content: space-between;

  &__item {
    width: calc(33.333% - 20px)
  }
}

.network__grid__item .network__visual {
  display: none;
  height: 320px;
}

.network-slider {
  .network__visual {
    height: 0;
    padding-bottom: 40%;
    background-position: center 30%;

    &[data-slide="3"] {
      background-position: center 55%;
    }
  }
}

@include below($tablet) {

  .network {
    padding-bottom: rem(40);
  }

  .network-slider {
    display: none;
  }

  .network__grid__item .network__visual {
    display: block;
  }

  .network__grid {
    display: block;
    margin-top: rem(40);
    margin-left: 0;
    margin-right: 0;
    padding: 0;
  }

  .network__grid__item {
    width: auto;
    margin-bottom: rem(40);

    .card {
      margin: rem(-60) rem(10) 0;
    }
  }

  .network__cta {
    margin-top: rem(40);
  }
}
