blockquote {
  color: $smoke;
  margin: 2em 0;
  padding-left: rem(30);
  border-left: 8px solid $mint;

  p {
    margin: 0;
  }

  footer {
    color: $mint;
    font-weight: 700;
    margin-top: 1em;
  }

  .future & {
    border-color: currentColor;
    &, footer {
      color: inherit;
    }
  }
}
