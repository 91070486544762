// .scrollmagic-pin-spacer {
//   background: rgba(red, .2);
// }

.pillars {
  position: relative;

  &__intro {
    height: 610px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    box-shadow: 0 0 8px rgba(#000, .2);
    overflow: hidden;

    @include below($tablet) {
      height: auto;
      padding: rem(40) 0;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba($smoke, .8);
      z-index: 2;
    }

    &__inner {
      height: 100%;
      display: flex;
      align-items: center;
      position: relative;
      z-index: 3;
    }
  }

  &__intro__content {
    p {
      max-width: 400px;
      color: $white;
      font-size: rem(20);
      line-height: lh(32,20);
      margin: 0;
    }
  }

  &__intro__title {
    margin-top: 0;
  }

}





.pillars__inner {
  position: relative;
}


.pillars__content {
  color: $white;
  position: relative;
  margin: 0;

  @include below($tablet) {
    background-color: $mint;
    background-image: $gradient-mint-vertical-rev;
    padding: 40px 0;
  }

  &__bg {
    background-color: $mint;
    background-image: $gradient-mint-vertical-rev;
    position: absolute;
    width: 100%;
    height: 100vh;
    z-index: 0;

    @include below($tablet) {
      height: auto;
      display: none;
    }
  }

  &__title {
    font-size: rem(32);
    line-height: lh(40,32);
    color: $white;
    margin: 0;
  }
}

.pillars__visuals {
  width: 50%;
  height: 100vh;
  position: absolute;
  right: 0;

  @include below($tablet) {
    display: none;
  }
}

.pillar {
  height: 100vh;
  position: relative;
  z-index: 1;

  @include below($tablet) {
    height: auto;
  }

  &--1 {
    @include above($tablet) {
      padding-top: 50vh;
    }
  }

  &__inner {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
  }

  &__content {
    width: 50%;
    max-width: 400px;
    margin-left: 0 !important;
    transform-origin: left top;

    @include below($tablet) {
      width: 100%;
    }
  }

  &__title {
    font-size: rem(32);
    line-height: lh(40,32);
    margin: 0 0 40px;
    font-weight: 400;
    color: $white;

    span { font-weight: 700; }

    @include below($tablet) {
      font-size: rem(28);
      line-height: lh(38,28);
      margin: 1.3em 0 0;
    }

    @include below($mobile) {
      font-size: rem(22);
      line-height: lh(32,22);
    }
  }
}

.pillar__visual {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: opacity 1s ease-in-out;
  opacity: 0;

  &.is-active {
    opacity: 1;
  }
}

.pillars__content__header {
  @include above($tablet) {
    width: 100%;
    position: absolute;
    z-index: 2;
  }
}

.pillars__content__title {
  color: $white;
  margin: 0;

  @include below($tablet) {
    font-size: rem(28);
  }

  @include below($mobile) {
    font-size: rem(22);
  }
}


.pillars__pager {
  display: none;

  @include above($tablet) {
    display: block;
    position: absolute;
    z-index: 2;
    height: 100vh;
    width: 100%;
    z-index: 1;
  }
}
