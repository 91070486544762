.future {
  position: relative;
  background-color: $mint;
  background-image: $gradient-mint-vertical;
  color: $white;
  padding: rem(70) 0;

  &__inner {
    position: relative;
  }

  &__title {
    color: inherit;
    margin: 0 0 rem(60) 0
  }

  &__intro {
    font-size: rem(20);
    line-height: lh(32,20);
  }

  &__content {
    width: calc(100% - 400px);
  }

  .stacked-logo {
    margin-top: 20px;
  }
}


.future__view {
  background-color: $white;
  background-image: url('../img/icn-wheel.svg');
  background-position: right 20px;
  background-repeat: no-repeat;
  box-shadow: 0 2px 8px 0 rgba(#000,.20);
  color: $body-color;
  padding: 30px;
  position: absolute;
  top: 0;
  right: 20px;
  bottom: rem(-170);
  border-radius: 4px 4px 0 0;
  width: 380px;

  &__title {
    font-weight: 700;
    color: $mint;
    font-size: rem(24);

    a {
      color: inherit;
      text-decoration: none;
    }
  }

  &__subtitle {
    font-weight: 400;
    font-size: rem(20);
  }

  p {
    font-size: rem(16);
  }
}


button.future__view__slider__button {
  display: block;
  font-size: rem(16);
  padding: rem(20) 0 0;
  margin-top: rem(10);
  width: 100%;
  border-top: 1px solid $mint;
  text-align: left;
  outline: none;
  display: flex;

  span {
    flex-grow: 1;
  }

  .icn {
    width: auto;
    height: auto;
  }
}

@include below($tablet) {
  .future {
    padding-bottom: 0;
  }

  .future__content {
    width: auto;
  }

  .future__view {
    box-shadow: 0 -2px 4px 0 rgba(#000,.20);
    position: relative;
    width: auto;
    left: auto;
    right: auto;
    margin-top: rem(40)
  }
}
