$base-color: $blue-light;

$border-color: lighten($base-color, 0);
$head-bg-color: lighten($base-color, 0);
$highlight-bg-color: lighten($base-color, 32);
$total-bg-color: lighten($base-color, 25);


.table {
  margin: 1rem 0;
  border-collapse: collapse;
  font-size: rem(14);

  th, td {
    padding: 1em;
    border: 1px solid $border-color;
  }

  &__key { text-align: left; }
  &__value { text-align: right; }

  &__head {
    th, td {
      white-space: nowrap;
      background-color: $head-bg-color;
      color: $white;
      border-width: 0 1px 0 0;
      border-color: rgba($white, .5);

      &:last-child {
        border-color: $border-color;
      }
    }
  }

  &__highlight {
    th, td {
      background-color: $highlight-bg-color;
    }

    .table__value {
      font-weight: 700;
    }
  }

  &__total {
    th, td {
      font-weight: 700;
      background-color: $total-bg-color;
      color: darken($base-color, 20);
    }
  }
}

.table.responsive {
  display: block;
  width: 100%;
  position: relative;

  .table__body {
    display: block;
    overflow-x: scroll;
    //padding-left: 119px;
  }

  .table__key {
    position: absolute;
    left: 0;
  }

  .table__key,
  .table__value {
    white-space: normal;
    border-bottom-width: 0;

    &[colspan="7"] {
      width: 120px * 7;
    }
  }

  tr:last-child {
    .table__key,
    .table__value {
      border-bottom-width: 1px;
    }
  }

  .table__key {
    box-shadow: 2px 0 1px rgba($border-color, .4);
  }
  .table__value {
    border-left-width: 0;

  }

  tr {
    display: block;
    @include clearfix();
    white-space: nowrap;
  }


  .table__row .table__key {
    background-color: $white;
  }
}

.table-legend {
  color: $smoke;
  display: flex;
  align-items: center;

  &.is-hidden {
    display: none;
  }

  &__text {
    font-size: rem(12);
    display: block;
    flex-grow: 1;
    text-align: left;
    opacity: .6;
    padding-right: 10px;
  }

  &__icn {
    display: block;
    color: $mint;
  }

  .icn {
    width: auto;
    height: auto;
    font-size: rem(24);
    margin-top: 3px;
  }

  .icn + .icn {
    margin-left: 10px;
  }
}

@include below(1200px) {
  .table {
    font-size: rem(12);
  }
}


@include below(960px) {
  .table {
    font-size: rem(12);
  }
}
