.percentage {
  color: $mint;
  position: relative;
  display: inline-block;
  padding: 0 20px 0 0;

  .visual {
    position: absolute;
    z-index: 0;
    right: 0;
    top: 0;
  }

  &__value {
    position: relative;
    z-index: 1;
    font-weight: 300;
    font-size: rem(144);
  }

  &__unit {
    font-weight: 400;
    font-size: .5em;
  }
}


