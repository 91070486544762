.event-content-area {
  // width: 50%;
  position: relative;
  z-index: 1;

  @include below($tablet) {
    display: none;
  }

  &__ui {
    position: relative;
    padding: 0 30px;
    max-width: 700px;
    margin: auto;

    .timeline-close {
      margin-right: 30px;
    }

  }

  &__placeholder {
    opacity: 1;
    position: relative;
    transform: translateY(0);
    transition-property: opacity, transform;
    transition-duration: $base-transition-duration;
    transition-timing-function: $base-transition-easing;

    &.is-hidden {
      opacity: 0;
      transform: translateY(100px);
    }
  }

  &__close {
    outline: 0;
    position: absolute;
    width: 20px;
    height: 20px;
    right: 0;
    z-index: 1;
    opacity: 1;
    transform: translateY(0);
    transition-property: opacity, transform;
    transition-duration: $base-transition-duration;
    transition-timing-function: $base-transition-easing;

    .icn {
      width: 100%;
      height: 100%;
    }

    &.is-hidden {
      opacity: 0;
      transform: translateY(100px);
    }

    @include below($tablet) {
      display: none;
    }
  }

}
