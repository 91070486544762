.timeline {

  &__content {
    @include clearfix();
  }

  &__detail {
    float: left;
    position: relative;
    width: 0;
    transition-property: width;
    transition-duration: $base-transition-duration;
    transition-timing-function: $base-transition-easing;

    &__inner {
      position: relative;
      max-width: 480px;
      padding: 0 rem(20);
      margin: auto;
    }
  }

  &__overview {
    float: right;
    width: 100%;
    ransition-property: width;
    transition-duration: $base-transition-duration;
    transition-timing-function: $base-transition-easing;
  }

  &__masonry {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    transition-property: width;
    transition-duration: $base-transition-duration;
    transition-timing-function: $base-transition-easing;
  }

  &.is-open {
    .timeline__masonry {
      transition-duration: 0s;
    }
  }


  &__title {
    position: relative;
    text-align: center;
    margin: 0;
    padding: rem(32) 0;
    background-color: $white;
    z-index: 2;

    &.is-sticky {
      position: fixed;
    }
  }

  &__block {
    transition-property: opacity, left, top, width, transform;
    transition-duration: $base-transition-duration;
    transition-timing-function: $base-transition-easing;

    &.is-hidden {
      display: none;
    }
  }

  .masonry-block {
    // transition-property: left, top, width, transform;
    // transition-duration: $base-transition-duration;
    // transition-timing-function: $base-transition-easing;
  }

  .breakout {
    position: absolute;
    opacity: 0;
  }

  .span-1 {
    width: 25%;
    overflow: hidden;
  }

  .span-2 {
    width: 50%;
    overflow: hidden;
  }
}








@include below($tablet) {
  .timeline {
    .span-1,
    .span-2 { width: 100%; }
  }
}


@include above($tablet) {
  .timeline__detail {
    min-height: 100%;
  }
  .timeline__overview {
    height: calc(100vh - 168px);
    position: relative;
    // height: calc(100vh - 64px);
  }

  .timeline__masonry {
    height: 100%;
    position: relative;
  }

  .timeline__block {
    height: 50%;
  }

  .timeline.is-open {

    .span-1,
    .span-2 { width: 50% !important; }

    .timeline__detail {
      width: 50%;
    }

    .timeline__overview {
      width: 50%;
    }

    .timeline__block {
      height: 33.333%;
    }
  }
}
