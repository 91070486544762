.footer {
  height: 100px;
  background-color: #fafafa;

  &__inner {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 rem(20);
  }
}


a.footer-link {
  color: $blue-light;
  font-size: rem(24);
  font-weight: 700;
  text-decoration: none;

  @include below($tablet) {
    font-size: rem(20);
  }

  @include below($mobile) {
    font-size: rem(18);
  }

  i {
    vertical-align: middle;
    display: inline-block;
    line-height: 1.6;
    width: auto;
    height: auto;
    margin-left: .3em;
  }

  &:hover {
    span {
      text-decoration: underline;
    }
  }
}
