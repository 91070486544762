.nav--lang {
  .nav__item {
    display: inline-block;
    margin-right: 1em;
    font-size: rem(12);

    a {
      text-transform: uppercase;
      color: $body-color;
    }

    &.is-active a {
      color: $mint;
    }
  }
}
