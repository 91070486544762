// ==========================================================================
// Base
// Base rules are the defaults. They are almost exclusively single element
// selectors but it could include attribute selectors, pseudo-class selectors,
// child selectors or sibling selectors. Essentially, a base style says that
// wherever this element is on the page, it should look like this. ==========================================================================

a, a:link, a:hover, a:active, a:visited {
  color: $link-color;
}

nav {
  ol,
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  a {
    text-decoration: none;
    color: inherit;
  }
}


button {
  padding: 0;
  appearance: none;
  border: none;
  background: transparent;
  cursor: pointer;
}

.wrapper {
  width: 100%;
  position: relative;
}

.screenReader {
  position: absolute;
  top: 0;
  left: -99999rem;
  display: block;
  height: 0;
  text-align: left;
  text-indent: -99999rem;
}

.clr {
  &-blue       { color: $blue; }
  &-blue-light { color: $blue-light; }
  &-mint       { color: $mint; }
  &-green      { color: $green; }
}
